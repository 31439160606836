<template>
  <CBadge v-bind="$attrs">
    <slot />
  </CBadge>
</template>

<script>
/**
 * RBadge
 *
 * Wrapper for CBadge with updated styles.
 */
export default {
  name: 'RBadge'
}
</script>

<style lang="scss" scoped>
.badge-primary {
  background-color: $badge-color-primary;
  color: $badge-font-color-primary;
}
.badge-secondary {
  background-color: $badge-color-secondary;
  color: $badge-font-color-secondary;
}
.badge-success {
  background-color: $badge-color-success;
  color: $badge-font-color-success;
}
.badge-warning {
  background-color: $badge-color-warning;
  color: $badge-font-color-warning;
}
.badge-danger {
  background-color: $badge-color-danger;
  color: $badge-font-color-danger;
}
.badge-info {
  background-color: $badge-color-info;
  color: $badge-font-color-info;
}
</style>
