<template>
  <ReportingCard title="Top Posters">
    <CRow
      v-for="poster in posters"
      :key="poster.id"
      class="my-2"
    >
      <CCol lg="9">
        <CLink :to="getPropertyLink(poster.id)" target="_blank" class="posters-list-item">
          {{ poster.name }}
        </CLink>
      </CCol>
      <CCol lg="3" class="text-right">
        <h6>{{ poster.posts.toLocaleString() }}</h6>
      </CCol>
    </CRow>
  </ReportingCard>
</template>

<script>
import { ReportingCard } from '@/views/reporting/_components'

export default {
  name: 'TopPostersList',
  components: {
    ReportingCard
  },
  props: {
    posters: {
      type: Array,
      required: true
    }
  },
  methods: {
    /**
     * Create the route link object for use with CLink.
     *
     * @param {Number} propertyId
     */
    getPropertyLink (propertyId) {
      const params = { cid: this.$route.params.cid, pid: propertyId }
      return { name: 'OverviewReport', params }
    }
  }
}
</script>

<style lang="scss" scoped>
.posters-list-item {
  font-weight: 600;
  color: $info;
}
</style>
