<template>
  <CRow class="widgets">
    <CCol xs="12" sm="6" lg="3">
      <ReportingWidget
        title="Total Emails"
        :text="data.emails.toLocaleString()"
        icon="md-email"
        :locked="!features.emailHandling"
      />
    </CCol>
    <CCol xs="12" sm="6" lg="3">
      <ReportingWidget
        title="Total Calls"
        :text="data.calls.toLocaleString()"
        icon="md-phone"
        :locked="!features.callTracking"
      />
    </CCol>
    <CCol xs="12" sm="6" lg="3">
      <ReportingWidget
        title="Total Website Visits"
        :text="data.redirects.toLocaleString()"
        icon="md-web"
        :locked="!features.redirectLinks"
      />
    </CCol>
    <CCol xs="12" sm="6" lg="3">
      <ReportingWidget
        title="Total Price Updates"
        :text="data.updates.toLocaleString()"
        icon="md-price-change"
        :locked="!features.priceUpdating"
      />
    </CCol>
  </CRow>
</template>

<script>
import { ReportingWidget } from '@/views/reporting/_components'

export default {
  name: 'LeadWidgets',
  components: {
    ReportingWidget
  },
  props: {
    data: {
      type: Object,
      required: true
    },
    features: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.widgets {
  .col {
    padding-right: 10px;
    padding-left: 10px;
  }
}
</style>
