<template>
  <ReportingCard title="Posts and Leads">
    <RChartArea
      :series="series"
      :options="chartOptions"
    />
  </ReportingCard>
</template>

<script>
import { eachDayOfInterval, format } from 'date-fns'

import { RChartArea } from '@/components/charts'
import { getChartLabels, getYAxisScale } from '@/utils'
import { ReportingCard } from '@/views/reporting/_components'

export default {
  name: 'PostsAndLeadsChart',
  components: {
    ReportingCard,
    RChartArea
  },
  props: {
    data: {
      type: Object,
      required: true
    },
    dateRange: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      yTickAmountPosts: 0,
      yTickAmountLeads: 0
    }
  },
  computed: {
    days () {
      return eachDayOfInterval({
        start: this.dateRange.start,
        end: this.dateRange.end
      })
    },
    series () {
      return [
        {
          name: 'Leads',
          type: 'area',
          data: this.aggregateLeadData(this.data.report)
        },
        {
          name: 'Posts',
          type: 'column',
          data: this.aggregatePostData(this.data.report)
        }
      ]
    },
    chartOptions () {
      return {
        chart: {
          height: 300
        },
        fill: {
          type: ['gradient', 'solid'],
          opacity: 1
        },
        stroke: {
          width: [4, 0]
        },
        yaxis: [
          {
            min: 0,
            max: () => {
              const { maxYAxis, yTickAmount } = getYAxisScale(Math.max(...this.series[0].data))
              this.yTickAmountLeads = yTickAmount
              return maxYAxis
            },
            tickAmount: this.yTickAmountLeads,
            title: {
              text: 'Leads'
            },
            labels: {
              formatter: (val) => { return val.toFixed(0) }
            }
          },
          {
            min: 0,
            max: () => {
              const { maxYAxis, yTickAmount } = getYAxisScale(Math.max(...this.series[1].data))
              this.yTickAmountPosts = yTickAmount
              return maxYAxis
            },
            tickAmount: this.yTickAmountPosts,
            opposite: true,
            title: {
              text: 'Posts'
            },
            labels: {
              formatter: (val) => { return val.toFixed(0) }
            }
          }
        ],
        xaxis: {
          labels: {
            rotate: -45,
            rotateAlways: true
          },
          categories: getChartLabels(this.days, 35) // 09-Mar
        },
        tooltip: {
          x: {
            formatter: (value, series) => {
              const day = this.days[series.dataPointIndex]
              return format(day, 'dd-MMM (EEE)') // 09-Mar (Mon)
            }
          }
        },
        legend: {
          height: 40,
          offsetY: 10
        }
      }
    }
  },
  methods: {
    /**
     * Aggregates posting data totals per day.
     *
     * @param {Array} report
     * @returns {Array}
     */
    aggregatePostData (report) {
      let data = []
      for (const property of report) {
        if (!data.length) {
          data = property.days.map(day => day.published)
        } else {
          data = property.days.map((day, index) => {
            return data[index] + day.published
          })
        }
      }
      return data
    },
    /**
     * Aggregates lead data totals per day.
     *
     * @param {Array} report
     * @returns {Array}
     */
    aggregateLeadData (report) {
      let data = []
      for (const property of report) {
        if (!data.length) {
          data = property.days.map(day => day.calls + day.emails + day.redirects)
        } else {
          data = property.days.map((day, index) => {
            return data[index] + day.calls + day.emails + day.redirects
          })
        }
      }
      return data
    }
  }
}
</script>
