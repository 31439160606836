<template>
  <div>
    <ReportingCard title="Detailed Property Posting">
      <CRow class="ml-0">
        <RTableFilterOptions
          ref="filters"
          :show-header="false"
          :config="filterConfig"
          @change="handleFilterChange"
        />
        <div class="ml-auto mr-3">
          <CButton class="ml-2" shape="pill" @click="showDataExportModal = true">
            <CIcon name="exporting" />
            Export
          </CButton>
        </div>
      </CRow>
      <RDataTable
        :items="tableData"
        :fields="columns"
      >
        <template #name="{ item }">
          <td>
            <CLink :to="getLocation(item)" target="_blank">
              {{ item.name }}
            </CLink>
            <RBadge v-if="item.premium" color="info">
              Premium Full Service
            </RBadge>
            <RBadge v-else-if="item.agency" color="info">
              Full Service
            </RBadge>
          </td>
        </template>
      </RDataTable>
    </ReportingCard>

    <DataExportModal
      :show="showDataExportModal"
      :data="exportData"
      :file-name="$route.name"
      @close="showDataExportModal = false"
    />
  </div>
</template>

<script>

import { RBadge } from '@/components/badges'
import { DataExportModal } from '@/components/modals'
import { RDataTable, RTableFilterOptions } from '@/components/tables'
import { ReportingCard } from '@/views/reporting/_components'

export default {
  name: 'PropertyPostingTable',
  components: {
    DataExportModal,
    RBadge,
    RDataTable,
    ReportingCard,
    RTableFilterOptions
  },
  props: {
    data: {
      type: Array,
      default: () => []
    },
    features: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      showDataExportModal: false,
      filters: {
        property: ''
      },
      filterConfig: {
        property: {
          type: 'input',
          label: 'Property Name'
        }
      }
    }
  },
  computed: {
    columns () {
      return [
        {
          key: 'name',
          label: 'Property Name',
          _style: 'width: 30%'
        },
        ...this.features.automatedPosting || this.features.postingService ? [{
          key: 'published',
          label: 'Ads Posted',
          _style: 'width: 13%',
          _classes: 'text-center'
        }] : [],
        ...this.features.scheduledPosting ? [{
          key: 'scheduled',
          label: 'Ads Scheduled',
          _style: 'width: 13%',
          _classes: 'text-center'
        }] : [],
        ...this.features.automaticRenewal ? [{
          key: 'renewed',
          label: 'Ads Renewed',
          _style: 'width: 13%',
          _classes: 'text-center'
        }] : [],
        ...this.features.emailHandling ? [{
          key: 'emails',
          label: 'Email Leads',
          _style: 'width: 10%',
          _classes: 'text-center d-none d-md-table-cell'
        }] : [],
        ...this.features.callTracking ? [{
          key: 'calls',
          label: 'Phone Leads',
          _style: 'width: 10%',
          _classes: 'text-center d-none d-md-table-cell'
        }] : [],
        ...this.features.redirectLinks ? [{
          key: 'redirects',
          label: 'Website Visits',
          _style: 'width: 10%',
          _classes: 'text-center d-none d-md-table-cell'
        }] : [],
        ...this.features.priceUpdating ? [{
          key: 'updates',
          label: 'Price Updates',
          _style: 'width: 10%',
          _classes: 'text-center d-none d-md-table-cell'
        }] : [],
        {
          key: 'total_leads',
          label: 'Total Leads',
          _style: 'width: 20%',
          _classes: 'text-center d-sm-table-cell d-md-none'
        }
      ]
    },
    exportData () {
      return this.tableData.map(prop => {
        const data = {
          /* eslint-disable quote-props */
          'Property Name': prop.name
        }
        if (this.features.automatedPosting || this.features.postingService) {
          data['Ads Posted'] = prop.published
        }
        if (this.features.scheduledPosting) {
          data['Ads Scheduled'] = prop.scheduled
        }
        if (this.features.automaticRenewal) {
          data['Ads Renewed'] = prop.renewed
        }
        if (this.features.emailHandling) {
          data['Email Leads'] = prop.emails
        }
        if (this.features.callTracking) {
          data['Phone Leads'] = prop.calls
        }
        if (this.features.redirectLinks) {
          data['Website Visits'] = prop.redirects
        }
        if (this.features.priceUpdating) {
          data['Price Updates'] = prop.updates
        }
        return data
      })
    },
    tableData () {
      this.data.forEach((prop) => {
        prop['total_leads'] = prop.calls + prop.emails + prop.redirects
      })

      return this.data.filter(prop => {
        let include = true
        if (this.filters.property !== '') {
          include &= prop.name.toLowerCase().includes(this.filters.property.toLowerCase())
        }
        return include
      })
    }
  },
  methods: {
    /**
     * Generate the route location object for navigating to
     * the property reporting view.
     *
     * @param {Object} row - table row item
     * @returns {Object}
     */
    getLocation (row) {
      return {
        name: 'Reporting',
        params: { cid: this.$route.params.cid, pid: row.id }
      }
    },
    /**
     * Update filters for posting report
     */
    handleFilterChange (filters) {
      this.filters = filters
    }
  }
}
</script>
