<template>
  <div v-if="company">
    <CRow align-horizontal="between" align-vertical="center" class="mb-4">
      <CCol sm="auto" class="d-flex align-items-center">
        <h2>
          Performance Report
        </h2>
      </CCol>
      <CCol sm="auto" class="d-flex align-items-center">
        <div class="pr-2 daterange-select">
          Showing reports for
        </div>
        <RDateRangePicker
          v-model="dateRange"
          :available-dates="{ start: min, end: max }"
          @dayclick="handleCalendarClick($event)"
        />
      </CCol>
    </CRow>
    <CRow>
      <CCol>
        <RSpinnerOverlay
          :loading="loading"
          :opacity="0.8"
          :z-index="1028"
          :is-full-page="true"
        >
          <CRow>
            <CCol>
              <Widgets :data="widgetData" :features="features" />
            </CCol>
          </CRow>
          <CRow>
            <CCol lg="8">
              <PostsAndLeadsChart :data="data" :date-range="dateRange" />
            </CCol>
            <CCol lg="4">
              <TopPostersList :posters="topPosters" />
            </CCol>
          </CRow>
          <CRow>
            <CCol>
              <LeadWidgets :data="widgetData" :features="features" />
            </CCol>
          </CRow>
          <CRow>
            <CCol md="12" lg="9">
              <LeadsByTypeChart :data="data.report" :date-range="dateRange" :features="features" />
            </CCol>
            <CCol md="12" lg="3">
              <LeadSourcesChart :data="leadBreakdown" title="Lead Breakdown" />
            </CCol>
          </CRow>
          <CRow>
            <CCol>
              <PropertyPostingTable :data="data.report" :features="features" />
            </CCol>
          </CRow>
        </RSpinnerOverlay>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import { addYears, differenceInCalendarDays, format, isPast, isValid, parseISO, subMonths, subYears } from 'date-fns'
import Mixpanel from 'mixpanel-browser'

import { RDateRangePicker } from '@/components/dates'
import { RSpinnerOverlay } from '@/components/spinners'
import { ReportingAPI } from '@/services/api/resources'
import { getFeatureMap } from '@/utils'
import LeadSourcesChart from '@/views/reporting/overview/LeadSourcesChart'

import LeadsByTypeChart from './LeadsByTypeChart'
import LeadWidgets from './LeadWidgets'
import PostsAndLeadsChart from './PostsAndLeadsChart'
import PropertyPostingTable from './PropertyPostingTable'
import TopPostersList from './TopPostersList'
import Widgets from './Widgets'

export default {
  name: 'CompanyPerformanceReport',
  /**
   * React to param changes to refresh the company data.
   */
  beforeRouteUpdate (to, from, next) {
    if (to.params.cid !== from.params.cid) {
      this.fetchPerformanceReport(to.params.cid)
    }
    next()
  },
  components: {
    PostsAndLeadsChart,
    PropertyPostingTable,
    TopPostersList,
    Widgets,
    LeadWidgets,
    LeadsByTypeChart,
    LeadSourcesChart,
    RDateRangePicker,
    RSpinnerOverlay
  },
  props: {
    company: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      loading: false,
      dateRange: {
        start: subMonths(new Date(), 1),
        end: new Date()
      },
      data: {
        features: [],
        report: []
      },
      min: null,
      max: new Date()
    }
  },
  computed: {
    widgetData () {
      return this.data.report.reduce((total, current) => {
        return {
          scheduled: total.scheduled + current.scheduled,
          published: total.published + current.published,
          renewed: total.renewed + current.renewed,
          calls: total.calls + current.calls,
          emails: total.emails + current.emails,
          redirects: total.redirects + current.redirects,
          updates: total.updates + current.updates
        }
      },
      {
        scheduled: 0,
        published: 0,
        renewed: 0,
        calls: 0,
        emails: 0,
        redirects: 0,
        updates: 0
      })
    },
    features () {
      const featureSet = new Set(this.data.features)
      return getFeatureMap(featureSet)
    },
    topPosters () {
      return this.data.report.map(poster => {
        return {
          id: poster.id,
          name: poster.name,
          posts: poster.published
        }
      }).sort((a, b) => {
        return b.posts - a.posts
      }).slice(0, 5).filter(poster => poster.posts > 0)
    },
    leadBreakdown () {
      const widgets = this.widgetData
      const leadBreakdown = {
        emails: widgets.emails,
        visits: widgets.redirects,
        calls: widgets.calls
      }
      const keys = Object.keys(leadBreakdown)
      for (const key of keys) {
        if (!leadBreakdown[key]) {
          delete leadBreakdown[key]
        }
      }
      return leadBreakdown
    }
  },
  watch: {
    dateRange () {
      this.fetchPerformanceReport(this.$route.params.cid)
    }
  },
  mounted () {
    if (process.env.NODE_ENV === 'production' && !this.$store.getters['auth/isStaff']) {
      Mixpanel.track('Company Performance Report', {
        Company: this.company.human_name
      })
    }
    // restore filters from route query
    const query = this.$route.query
    const keys = Object.keys(query)
    if (keys.length > 0) {
      for (const key of keys) {
        if (key === 'start' || key === 'end') {
          const date = parseISO(query[key])
          if (isValid(date) && isPast(date)) {
            this.dateRange[key] = date
          }
        }
      }
    }
    // If the date range is more than a year, modify end date to be a year from start date
    if ((differenceInCalendarDays(this.dateRange['end'], this.dateRange['start'])) > 365) {
      this.dateRange['end'] = addYears(this.dateRange['start'], 1)
    }
    // If the end date is past today, change it to today's date
    if (this.dateRange['end'] > new Date()) {
      this.dateRange['end'] = new Date()
    }

    if (this.dateRange['end'] < this.dateRange['start']) {
      alert('The date range you selected is invalid, please try again!')
    } else {
      this.fetchPerformanceReport(this.$route.params.cid)
    }
  },
  methods: {
    /**
     * Handle disabling dates based on selected date
     *
     * @param {Object} date: contains selected date information
     */
    handleCalendarClick (date) {
      this.min = subYears(date.date, 1)
      this.max = addYears(date.date, 1)
      if (this.max > new Date()) {
        this.max = new Date()
      }
    },
    /**
     * Fetch the performance report from the API
     */
    async fetchPerformanceReport (companyId) {
      try {
        this.loading = true
        const params = new URLSearchParams()
        params.append('start', format(this.dateRange.start, 'yyyy-MM-dd'))
        params.append('end', format(this.dateRange.end, 'yyyy-MM-dd'))
        const response = await ReportingAPI.company.performance({
          id: companyId,
          params
        })
        if (response) {
          this.data = response.data
        }

        // save params as route query
        const query = {}
        params.forEach((value, key) => {
          if (query.hasOwnProperty(key)) {
            if (Array.isArray(query[key])) {
              query[key].push(value)
            } else {
              query[key] = [query[key], value]
            }
          } else {
            query[key] = key === 'end' ? format(parseISO(value), 'yyyy-MM-dd') : value
          }
        })
        this.$router.replace({ path: this.$route.path, query: query }).catch(err => err)
      } catch (err) {
        this.$store.dispatch('error/show', err)
      } finally {
        this.loading = false
        this.min = null
        this.max = new Date()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .input {
  color: $info;
}
.daterange-select {
  color: $info;
  font-size: 1.1rem;
}
</style>
