<template>
  <ReportingCard title="Leads by Type">
    <RChartColumn :series="series" :options="chartOptions" />
  </ReportingCard>
</template>

<script>
import { eachDayOfInterval, format } from 'date-fns'

import { RChartColumn } from '@/components/charts'
import { getChartLabels, getYAxisScale } from '@/utils'
import { ReportingCard } from '@/views/reporting/_components'

export default {
  name: 'LeadsByTypeChart',
  components: {
    ReportingCard,
    RChartColumn
  },
  props: {
    data: {
      type: Array,
      required: true
    },
    dateRange: {
      type: Object,
      required: true
    },
    features: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      yTickAmount: 0
    }
  },
  computed: {
    days () {
      return eachDayOfInterval({
        start: this.dateRange.start,
        end: this.dateRange.end
      })
    },
    series () {
      // e.g. [{ data: [0, 2, 2, 1], name: 'Visits' }]
      return [
        ...this.features.emailHandling
          ? [{
            name: 'Emails',
            data: this.aggregateLeadData(this.data, 'emails')
          }] : [],
        ...this.features.redirectLinks
          ? [{
            name: 'Visits',
            data: this.aggregateLeadData(this.data, 'redirects')
          }] : [],
        ...this.features.callTracking
          ? [{
            name: 'Calls',
            data: this.aggregateLeadData(this.data, 'calls')
          }] : []
      ]
    },
    chartOptions () {
      return {
        chart: {
          height: 300
        },
        // set y axis to scale of 5's
        yaxis: {
          min: 0,
          max: (max) => {
            const { maxYAxis, yTickAmount } = getYAxisScale(max)
            this.yTickAmount = yTickAmount
            return maxYAxis
          },
          tickAmount: this.yTickAmount
        },
        xaxis: {
          labels: {
            rotate: -45,
            rotateAlways: true
          },
          categories: getChartLabels(this.days, 35) // 09-Mar
        },
        tooltip: {
          x: {
            formatter: (value, series) => {
              const day = this.days[series.dataPointIndex]
              return format(day, 'dd-MMM (EEE)') // 09-Mar (Mon)
            }
          }
        },
        legend: {
          showForSingleSeries: true
        }
      }
    }
  },
  methods: {
    /**
     * Aggregates lead data totals per day.
     *
     * @param {Array} report
     * @param {String} leadType - key for which lead type
     * @returns {Array}
     */
    aggregateLeadData (report, leadType) {
      let data = []
      for (const property of report) {
        if (!data.length) {
          data = property.days.map(day => day[leadType])
        } else {
          data = property.days.map((day, index) => {
            return data[index] + day[leadType]
          })
        }
      }
      return data
    }
  }
}
</script>
