<template>
  <CRow class="widgets">
    <CCol xs="12" sm="6" lg="3">
      <ReportingWidget
        title="Total Ads Posted"
        :text="data.published.toLocaleString()"
        icon="md-check-circle"
        :locked="!features.automatedPosting && !features.postingService"
      />
    </CCol>
    <CCol xs="12" sm="6" lg="3">
      <ReportingWidget
        title="Total Renewals"
        :text="data.renewed.toLocaleString()"
        icon="md-sync"
        :locked="!features.automaticRenewal"
      />
    </CCol>
    <CCol xs="12" sm="6" lg="3">
      <ReportingWidget
        title="Total Ads Scheduled"
        :text="data.scheduled.toLocaleString()"
        icon="md-schedule"
        :locked="!features.scheduledPosting"
      />
    </CCol>
    <CCol xs="12" sm="6" lg="3">
      <ReportingWidget
        title="Total Leads"
        :text="(data.calls + data.emails + data.redirects).toLocaleString()"
        icon="fa-filter-circle-dollar"
        :locked="!features.emailHandling && !features.callTracking && !features.redirectLinks"
      />
    </CCol>
  </CRow>
</template>

<script>
import { ReportingWidget } from '@/views/reporting/_components'

export default {
  name: 'Widgets',
  components: {
    ReportingWidget
  },
  props: {
    data: {
      type: Object,
      required: true
    },
    features: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.widgets {
  .col {
    padding-right: 10px;
    padding-left: 10px;
  }
}
</style>
